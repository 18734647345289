var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { cloneElement } from 'react';
import cx from 'classnames';
export const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const TIMES_OF_DAY = ['morning', 'midday', 'evening'];
const Block = ({ title, entries }) => (React.createElement(React.Fragment, null,
    React.createElement("h3", { className: "mb-2 text-center font-semibold uppercase tracking-wider" }, title),
    entries
        .slice()
        .sort((a, b) => TIMES_OF_DAY.indexOf(a.timeOfDay) - TIMES_OF_DAY.indexOf(b.timeOfDay))
        .map(({ key, weekday, timeOfDay, content }) => cloneElement(content, Object.assign(Object.assign({}, content.props), { key, className: cx(`schedule-${weekday} schedule-${timeOfDay}`, content.props.className) })))));
const WeekSchedule = (_a) => {
    var { monday, tuesday, wednesday, thursday, friday, saturday, className } = _a, rest = __rest(_a, ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "className"]);
    return (React.createElement("div", Object.assign({ className: cx('schedule-grid', className) }, rest),
        React.createElement(Block, { title: "Montag", entries: monday }),
        React.createElement(Block, { title: "Dienstag", entries: tuesday }),
        React.createElement(Block, { title: "Mittwoch", entries: wednesday }),
        React.createElement(Block, { title: "Donnerstag", entries: thursday }),
        React.createElement(Block, { title: "Freitag", entries: friday }),
        React.createElement(Block, { title: "Samstag", entries: saturday })));
};
export default WeekSchedule;
