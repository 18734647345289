var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { createContext, isValidElement, useContext, } from 'react';
import { useField } from 'formik';
import cx from 'classnames';
import Icon from './Icon';
export const BotField = () => (React.createElement("div", { className: "hidden" },
    React.createElement("label", null,
        "Don't fill this out if you're human: ",
        React.createElement("input", { name: "bot-field" }))));
export function makeValidator(name) {
    return (value) => (value.length === 0 ? `${name} ist erforderlich` : undefined);
}
export function urlEncode(data) {
    return Object.keys(data)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
}
const FieldControlContext = createContext({ name: '', hasError: false });
export function useFieldValues(props) {
    var _a, _b;
    const control = useContext(FieldControlContext);
    const name = (_a = props.name) !== null && _a !== void 0 ? _a : control.name;
    if (name == null) {
        throw new Error('Name must be specified. Either wrap field in <FieldControl /> or specify name directly.');
    }
    const [input, meta, helper] = useField(Object.assign(Object.assign({}, props), { name }));
    const hasError = (_b = control.hasError) !== null && _b !== void 0 ? _b : (meta.error != null && meta.touched);
    return {
        field: {
            input,
            meta,
            helper,
        },
        name,
        hasError,
    };
}
export const FieldControl = (_a) => {
    var { name, helperText, children, className } = _a, rest = __rest(_a, ["name", "helperText", "children", "className"]);
    const [, meta] = useField(name);
    const hasError = meta.error != null && meta.touched;
    return (React.createElement("div", Object.assign({ className: cx('flex flex-col space-y-1.5', className) }, rest),
        React.createElement(FieldControlContext.Provider, { value: { name, hasError } }, children),
        hasError ? (React.createElement("span", { className: "text-xs text-red-500" }, meta.error)) : isValidElement(helperText) ? (helperText) : helperText ? (React.createElement("span", { className: "text-xs" }, helperText)) : null));
};
const FieldLabel = ({ dark, children }) => (React.createElement("span", { className: cx('field-label', dark ? 'text-white' : 'text-gray-700') }, children));
function getInputStyleClasses(hasError) {
    return cx('w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 disabled:cursor-not-allowed disabled:shadow-none disabled:border-0 disabled:bg-gray-200 disabled:opacity-50', hasError
        ? 'border-red-500 focus:border-red-500 focus:ring-red-400'
        : 'border-gray-300 focus:border-gray-500 focus:ring-gray-400');
}
export const Input = (_a) => {
    var { hasError, className } = _a, rest = __rest(_a, ["hasError", "className"]);
    return (React.createElement("input", Object.assign({ className: cx(getInputStyleClasses(hasError === true), className) }, rest)));
};
export const InputField = (props) => {
    const { label, dark, icon, validate, className } = props, rest = __rest(props, ["label", "dark", "icon", "validate", "className"]);
    const { field, hasError } = useFieldValues(props);
    return (React.createElement("label", { className: cx('form-field', className) },
        isValidElement(label) ? label : React.createElement(FieldLabel, { dark: dark }, label),
        icon ? (React.createElement("div", { className: "relative" },
            React.createElement("div", { className: "absolute inset-y-0 pl-3 flex items-center" }, isValidElement(icon) ? icon : React.createElement(Icon, { name: icon })),
            React.createElement(Input, Object.assign({ className: "pl-10", hasError: hasError }, field.input, rest)))) : (React.createElement(Input, Object.assign({ hasError: hasError }, field.input, rest)))));
};
const TextArea = (_a) => {
    var { hasError, className } = _a, rest = __rest(_a, ["hasError", "className"]);
    return (React.createElement("textarea", Object.assign({ className: cx(getInputStyleClasses(hasError === true), className) }, rest)));
};
export const TextAreaField = (props) => {
    const { label, dark, validate, className } = props, rest = __rest(props, ["label", "dark", "validate", "className"]);
    const { field, hasError } = useFieldValues(props);
    return (React.createElement("label", { className: cx('form-field', className) },
        isValidElement(label) ? label : React.createElement(FieldLabel, { dark: dark }, label),
        React.createElement(TextArea, Object.assign({ hasError: hasError }, field.input, rest))));
};
export const Select = (_a) => {
    var { hasError, className } = _a, rest = __rest(_a, ["hasError", "className"]);
    return (React.createElement("select", Object.assign({ className: cx(getInputStyleClasses(hasError === true), className) }, rest)));
};
export const SelectField = (props) => {
    const { label, dark, validate, className } = props, rest = __rest(props, ["label", "dark", "validate", "className"]);
    const { field, hasError } = useFieldValues(props);
    return (React.createElement("label", { className: cx('form-field', className) },
        isValidElement(label) ? label : React.createElement(FieldLabel, { dark: dark }, label),
        React.createElement(Select, Object.assign({ hasError: hasError }, field.input, rest))));
};
export const CheckInputField = (props) => {
    const { label, dark, validate, className } = props, rest = __rest(props, ["label", "dark", "validate", "className"]);
    const { field } = useFieldValues(props);
    return (React.createElement("label", { className: cx('inline-flex items-center space-x-2', {
            ['cursor-not-allowed text-gray-200 opacity-50']: rest.disabled,
        }, className) },
        React.createElement("input", Object.assign({ className: "rounded shadow-sm text-gray-700 border-gray-300 focus:ring-offset-2 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:shadow-none disabled:border-0 disabled:bg-gray-200" }, field.input, rest)),
        isValidElement(label) ? label : React.createElement(FieldLabel, { dark: dark }, label)));
};
