var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cx from 'classnames';
import React, { isValidElement } from 'react';
import Icon from './Icon';
const BUTTON_SIZES = {
    sm: 'h-7 px-2 text-xs',
    md: 'h-9 px-4 text-sm',
    lg: 'h-12 px-6 text-base',
    xl: 'h-14 px-8 text-xl',
};
const BUTTON = 'inline-flex items-center justify-center font-semibold transition duration-150 ease-out rounded select-none whitespace-nowrap focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-800';
function getButtonShapeColorClasses(shape, colorScheme) {
    const shadow = 'shadow-sm hover:shadow active:shadow-none disabled:shadow-none';
    const grayText = 'text-gray-600 hover:text-gray-700 active:text-gray-700 hover:bg-gray-200 active:bg-gray-300';
    const orangeText = 'text-orange-600 hover:text-orange-700 active:text-orange-700 hover:bg-orange-50 active:bg-orange-100';
    const redText = 'text-red-600 hover:text-red-700 active:text-red-700 hover:bg-red-50 active:bg-red-100';
    switch (shape) {
        case 'filled':
            return cx(shadow, {
                ['text-white bg-gray-600 hover:bg-gray-700 active:bg-gray-800 focus-visible:ring-gray-50']: colorScheme === 'gray',
                ['text-white bg-orange-500 hover:bg-orange-600 active:bg-orange-700 focus-visible:ring-orange-300']: colorScheme === 'orange',
                ['text-white bg-red-500 hover:bg-red-600 active:bg-red-700 focus-visible:ring-red-300']: colorScheme === 'red',
            });
        case 'outlined':
            return cx(shadow, 'border', {
                [`${grayText} border-gray-600 focus-visible:ring-gray-50`]: colorScheme === 'gray',
                [`${orangeText} border-orange-500 focus-visible:ring-orange-300`]: colorScheme === 'orange',
                [`${redText} focus-visible:ring-red-300`]: colorScheme === 'red',
            });
        case 'text':
            return cx({
                [`${grayText} focus-visible:ring-gray-50`]: colorScheme === 'gray',
                [`${orangeText} focus-visible:ring-orange-300`]: colorScheme === 'orange',
                [`${redText} focus-visible:ring-red-300`]: colorScheme === 'red',
            });
        default:
            return '';
    }
}
function getIconSize(buttonSize = 'md') {
    switch (buttonSize) {
        case 'sm':
        case 'md':
            return 'md';
        case 'lg':
        case 'xl':
            return 'lg';
    }
}
export const ButtonContent = ({ icon, size, loading, children, }) => {
    function getContent() {
        if (icon) {
            const iconElement = isValidElement(icon) ? icon : React.createElement(Icon, { name: icon, size: getIconSize(size) });
            return children ? (React.createElement("div", { className: "flex items-center space-x-2" },
                iconElement,
                React.createElement("span", null, children))) : (iconElement);
        }
        else {
            return children;
        }
    }
    const content = getContent();
    if (loading) {
        return (React.createElement("div", { className: "relative flex justify-center items-center" },
            React.createElement("span", { className: "absolute spinner" }),
            React.createElement("span", { className: "invisible" }, content),
            React.createElement("span", { className: "sr-only" }, "Laden\u2026")));
    }
    else {
        return content;
    }
};
export function getButtonStyleClasses({ shape = 'filled', colorScheme = 'gray', size = 'md', }) {
    return cx(BUTTON, getButtonShapeColorClasses(shape, colorScheme), BUTTON_SIZES[size]);
}
export const Button = (_a) => {
    var { shape, colorScheme, size, icon, loading, disabled, children, className } = _a, rest = __rest(_a, ["shape", "colorScheme", "size", "icon", "loading", "disabled", "children", "className"]);
    return (React.createElement("button", Object.assign({ className: cx(getButtonStyleClasses({ shape, colorScheme, size }), className), disabled: loading || disabled }, rest),
        React.createElement(ButtonContent, { icon: icon, loading: loading, size: size }, children)));
};
export const AnchorButton = (_a) => {
    var { shape, colorScheme, size, icon, children, className } = _a, rest = __rest(_a, ["shape", "colorScheme", "size", "icon", "children", "className"]);
    return (React.createElement("a", Object.assign({ className: cx(getButtonStyleClasses({ shape, colorScheme, size }), className) }, rest),
        React.createElement(ButtonContent, { icon: icon, size: size }, children)));
};
export function getIconButtonStyleClasses({ shape = 'filled', colorScheme = 'gray', size = 'md', }) {
    return cx(BUTTON, getButtonShapeColorClasses(shape, colorScheme), {
        ['h-7 w-7']: size === 'sm',
        ['h-9 w-9']: size === 'md',
        ['h-12 w-12']: size === 'lg',
        ['h-14 w-14']: size === 'xl',
    });
}
export const IconButton = (_a) => {
    var { shape, colorScheme, size, icon, loading, disabled, label, className } = _a, rest = __rest(_a, ["shape", "colorScheme", "size", "icon", "loading", "disabled", "label", "className"]);
    return (React.createElement("button", Object.assign({ className: cx(getIconButtonStyleClasses({ shape, colorScheme, size }), className), "aria-label": label, title: label, disabled: loading || disabled }, rest),
        React.createElement(ButtonContent, { icon: icon, loading: loading, size: size })));
};
export const CloseButton = (_a) => {
    var { mode = 'light', className } = _a, rest = __rest(_a, ["mode", "className"]);
    return (React.createElement(IconButton, Object.assign({ className: cx('bg-opacity-0 hover:bg-opacity-5 active:bg-opacity-10 text-current', {
            ['bg-black']: mode === 'light',
            ['bg-white']: mode === 'dark',
        }, className), colorScheme: "custom", shape: "text", icon: "x", label: "Schliessen" }, rest)));
};
